export default {
  /* eslint  quotes: ["warn", "double"], indent: ["warn", 2] */
  "translation": {
    "common": {
      "success": {
        "changes": "Cambios guardados"
      },
      "errors": {
        "basic": "Algo ha ido mal. Por favor, inténtelo de nuevo"
      },
      "tags": {
        "is_optional": "opcional",
      },
      "ctas": {
        "cancel": "Cancelar",
        "save_changes": "Guardar cambios",
      },
      "options": {
        "yes": "Sí",
        "no": "No",
      },
    },
    "email": {
      "sender": "Correo electrónico Remitente",
      "subject": "Asunto",
      "body": "Cuerpo del email",
      "cta": "Enviar email",
      "cta_group": "Enviar correo de grupo",
      "recipients_one": "Destinatario:",
      "recipients_other": "Destinatarios:",
    },
    "cohorts": {
      "settings": {
        "cohort_status": {
          "active_state": {
            "title": "Activa",
            "text": "Esta opción habilita la visibilidad de la cohorte. Los miembros de la cohorte podrán ver y participar en los informes de la cohorte. Asegúrese de utilizar esta función cuando la cohorte esté lista para ser compartida con el grupo."
          },
          "inactive_state": {
            "title": "Inactivo",
            "text": "Esta opción desactiva la visibilidad de la cohorte. Esto asegura que el contenido de la cohorte permanece privado y no es visible para los miembros de la cohorte. Esta es la opción por defecto cuando se acaba de crear una cohorte."
          },
          "confirm_active": {
            "title": "Confirmar visibilidad de cohorte",
            "body": "Al confirmar, está habilitando la visibilidad de la cohorte. Los miembros de la cohorte tendrán acceso para ver y participar en los informes de la cohorte. Esta acción debe llevarse a cabo cuando la cohorte esté lista para ser compartida con el grupo.\nEsto asegura que el contenido de la cohorte será visible para todos los miembros en el perfil de la empresa. ¿Está seguro de que desea continuar?",
            "cta": "Activar visibilidad",
          },
          "confirm_inactive": {
            "title": "Confirmar privacidad de la cohorte",
            "body": "Al confirmar, estás deshabilitando la visibilidad de la cohorte. Esto asegura que el contenido de la cohorte sigue siendo privado y no es visible para los miembros de la cohorte. Esto significa que los informes de la cohorte ya no serán accesibles a los miembros en el perfil de la empresa. ¿Está seguro de que desea proceder con la desactivación de la visibilidad?",
            "cta": "Desactivar visibilidad",
          }
        },
        "messaging": {
          "edit_email": "Editar correo electrónico",
          "modal_title": "Correo electrónico",
          "reporting": {
            "title": "Correos electrónicos de notificación",
          },
          "reminder": {
            "title": "Activar correos electrónicos recordatorios",
            "body": "Habilita los correos electrónicos recordatorios que salen a los miembros antes de cada fecha de presentación de informes",
          },
        },
      },
    },
    "venture_add": {
      "title_step_1": "Detalles de la empresa",
      "title_step_2": "Venture contacto y audiencia",
      "title_step_3": "Presencia en línea",
      "customer_type_modal": {
        "title": "Más información por tipo de clientes"
      },
      "step_counter": "Paso {{counter}} de {{total}}",
      "form_controls": {
        "previous": "Anterior",
        "next": "Continuar",
        "submit": "Guardar y ver perfil de empresa",
      },
      "field_titles": {
        "company_name": "¿Cuál es el nombre de su empresa?",
        "choose_global": "En todo el mundo",
        "pitch_video_url": "Por ejemplo, http://vimeo.com/28175752. Tu vídeo se incrustará en tu perfil de empresa",
        "tagline": "Tagline de l'entreprise",
      },
      "field_labels": {
        "address_main": "Dirección completa",
        "company_name": "Nombre de la empresa",
        "city": "Ciudad",
        "country": "País",
        "customer_base": "Base de clientes",
        "customer_type": "Tipo de clientes",
        "focus_locations": "Países",
        "founding_date": "Fecha de fundación",
        "phone_number": "Número de teléfono",
        "pitch_video_url": "URL del vídeo de presentación",
        "pitch": "Discurso",
        "sectors": "Sectores",
        "stage": "Fase",
        "street_address": "Dirección",
        "tagline": "Nombre de la empresa",
        "website_url": "Su sitio web",
      },
      "field_help_text": {
        "address_auto": "Rellene la dirección y confirme seleccionando la dirección de la lista",
        "address_m_text": "¿No encuentras la dirección? Prueba con un punto de referencia o ciudad cercana o",
        "address_m_link": "Introduzca la dirección manualmente",
        "customer_type": "Se pueden seleccionar varios modelos de cliente",
        "customer_type_link": "Ver más detalles de los modelos",
        "focus_locations": "Seleccione los países a los que se dirige actualmente con su producto o servicio.",
        "phone_number": "Por favor, incluya cualquier número del país",
        "pitch_video_url": "Puede ser la URL de tu vídeo de Instagram, YouTube, Vimeo, DailyMotion o presentación de Slideshare.",
        "pitch": "Sea lo más breve, conciso y directo posible. Díganos qué hace y por qué su empresa tiene potencial.",
        "sectors": "¿En qué sector es más activa su empresa? Seleccione un máximo de 3 sectores",
        "tagline": "Cuéntenos en pocas palabras en qué consiste su negocio.",
        "website_url": "Si no tiene un sitio web, proporcione la página de medios sociales de su empresa (como Facebook o LinkedIn).",
      },
      "field_placeholders": {
        "pitch_video_url": "Añade aquí tu enlace completo de YouTube o Vimeo. Asegúrate de que empieza por http://"
      },
      "field_customer_base": {
        "titles": {
          "urban": "Clientes de base urbana",
          "rural": "Clientes rurales",
        },
        "descriptions": {
          "urban": "Los clientes se encuentran principalmente en pueblos y ciudades.",
          "rural": "Los clientes se encuentran sobre todo en pueblos y zonas rurales.",
        }
      },
      "remove_member_btn": "Eliminar de cohorte",
    },
    "validations": {
      "required": "Este campo es obligatorio",
      "minValue": "Este campo debe ser al menos {min}",
      "maxValue": "Este campo debe ser como máximo {max}",
      "email": "Por favor, introduzca una dirección de correo electrónico válida",
      "minLength": "Este campo debe contener como mínimo {min} caracteres",
      "maxLength": "Este campo debe contener como máximo {max} caracteres",
      "integer": "Este valor debe ser un número entero",
    },
    "initial_estimate": {
      "heading": {
        "title": "Estimación inicial",
        "description": "Empieza creando una estimación de tu empresa basada en lo que valen otras empresas",
        "section_venture_basics": "Conceptos básicos",
        "section_venture_stage": "Etapa de riesgo",
        "section_venture_operations": "Operaciones de riesgo"
      },
      "form": {
        "founder_number": "¿Cuántos fundadores tiene {ventureName}?",
        "employee_number": "¿Cuántos empleados trabajan para {ventureName}?",
        "country": "¿En qué país tiene su sede {ventureName}?",
        "industry": "¿A qué industria pertenece {ventureName}?",
        "started_year": "¿Cuándo se creó la empresa?",
        "dev_stage": "¿En qué fase de desarrollo se encuentra ahora?",
        "ip_type": "¿Qué tipo de Propiedad Intelectual (PI) se aplica al producto/servicio?",
        "profitability": "¿Es {ventureName} sosteniblemente rentable?",
        "revenue_y1": "Ingresos en el último año ($)",
        "revenue_y4": "Previsiones de ingresos para los próximos 3 años ($)",
      },
      "dev_stage_options": {
        "type1_title": "Etapa de ideas",
        "type1_desc": "Trabajar en un plan de negocio y probar las hipótesis iniciales de problema-solución",
        "type2_title": "Etapa de desarrollo",
        "type2_desc": "Construir el producto y establecer las operaciones",
        "type3_title": "Etapa de puesta en marcha",
        "type3_desc": "Activa con ingresos anuales de hasta 300.000 dólares",
        "type4_title": "Etapa de expansión",
        "type4_desc": "Escalando operaciones, con ingresos anuales superiores a 300.000 dólares",
        "type5_title": "Etapa de crecimiento",
        "type5_desc": "Un negocio considerable con un crecimiento sólido, probado y repetible",
        "type6_title": "Etapa de madurez",
        "type6_desc": "Un negocio rentable con crecimiento predecible alineado con la industria"
      },
      "ip_type_options": {
        "type1_title": "Marca comercial y/o nombres de dominio",
        "type2_title": "Derechos de autor",
        "type3_title": "Patente (excluida la patente provisional estadounidense)",
        "type4_title": "Otros",
        "type5_title": "Ninguno o no aplicable",
      },
      "profitability_options": {
        "type1_title": "Sí",
        "type2_title": "No",
      },
      "controls":{
        "back": "Volver",
        "continue": "Continúe en",
        "cta": "Estimar mi valoración"
      },
      "results": {
        "title": "Estimación inicial completa",
        "desc": "Se ha estimado inicialmente que {ventureName} se valora entre {valuationRange}",
        "cta": "Volver al perfil de la empresa",
        "loading": {
          "title": "Haciendo números...",
          "desc": "Estamos generando su presupuesto inicial. No le llevará mucho tiempo, sólo unos segundos."
        },
        "error": {
          "title": "¡Uy! Algo salió mal",
          "desc": "Se ha producido un error. Vuelva a intentarlo o actualice la página si el problema persiste.",
          "cta": "Por favor, inténtelo de nuevo"
        }
      }
    },
    "biz_valuation": {
      "empty_state": {
        "desc": "Aproveche la metodología líder del sector y los datos de mercado para calcular una valoración preliminar de su emprendimiento",
        "cta": "Obtén una estimación inicial"
      },
      "title": "Herramienta de valoración de empresas",
      "high_bound_valuation": "Valoración límite superior",
      "low_bound_valuation": "Valoración límite bajo",
      "venture_estimate": "Se ha estimado que su empresa tiene un valor entre {range}. {cta}",
      "edit_estimate_cta": "Editar estimación inicial",
      "goto_equidam_explainer": "¿Necesita un informe de valoración completo? {cta} y disfruta de un 10% de descuento.",
      "goto_equidam_cta": "Proceder con Equidam",
      "powered_by": "Desarrollado por"
    }
  },
};
