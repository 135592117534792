// Helper wrappers that allow i18n support for vuelidate. Check https://vuelidate-next.netlify.app/advanced_usage.html#i18n-support

import * as validators from '@vuelidate/validators';
import i18n from '@/i18n';
const { createI18nMessage } = validators;

// Create i18n message instance
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })

export const minLength = withI18nMessage(validators.minLength, {
    withArguments: true,
});
export const maxLength = withI18nMessage(validators.maxLength, {
    withArguments: true,
});
export const minValue = withI18nMessage(validators.minValue, {
    withArguments: true,
});
export const maxValue = withI18nMessage(validators.maxValue, {
    withArguments: true,
});
export const email = withI18nMessage(validators.email);
export const integer = withI18nMessage(validators.integer);

// Allows nested keys to be passed for $model e.g. 'form.email'
function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
}

// Function can be imported into Vue components and returns the first error message from Vuelidate if any
export const getErrorMessage = function ($model) {
    const nestedValue = getNestedValue(this.v$, $model);
    return nestedValue && nestedValue.$error ? nestedValue.$errors[0].$message : null;
};
